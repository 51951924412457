import axios from "axios";

import store from "@/store";

import { showToast } from "@/utils/toast";
import { decodeByAES256, encodeByAES256 } from "@/utils/crypto";

// 기본 baseURL을 설정합니다.
const defaultBaseURL = process.env.VUE_APP_BASE_API_URL;

// 인터셉터를 생성할 함수를 정의합니다.
function createAPI(baseURL) {
  return axios.create({
    baseURL: baseURL,
  });
}

// Request interceptor to add the token to each request
const api = createAPI(defaultBaseURL);

function isNeedPrintOut( config ){
  return ( 
    config.url.includes('/printouts') && config.method === 'get'
  )
}

// Request interceptor to add the token to each request
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  const access = decodeByAES256( token );

  // 버전이 같은지 판단합니다.
  const pastVersion = decodeByAES256( window.localStorage.getItem("version") );
  const thisVersion = process.env.VUE_APP_VERSION;
  if( pastVersion !== thisVersion && access ){
    alert('최신 버전이 존재합니다. 업데이트를 진행합니다.');
    const version = encodeByAES256( thisVersion );
    window.localStorage.setItem("version", version);
    window.location.reload();
  }

  if (
    access &&
    !config.url.includes("login") &&
    !config.url.includes("refresh")
  ) {
    config.headers.Authorization = `Bearer ${access}`;
  }

  if (isNeedPrintOut(config)) {
    // config['Accept'] = 'text/csv';
    config.headers['Accept'] = '*/*';
    // config.headers['Content-Type'] = 'application/octet-stream';
    // config.headers['Content-Type'] = 'text/csv; charset=utf-8';
    // config.headers['Content-Disposition'] = `attachment; filename="${fileName}";`
    // config.headers['Pragma'] = `no-cache`;
    // config.headers['Expires'] = 0;
  }

  return config;
});

// Response interceptor to refresh token if needed
// 디바운스 함수 정의
function debounce(func, delay) {
  let timer;
  return function() {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, arguments);
    }, delay);
  };
}

// 디바운스 함수를 이용하여 에러 핸들링 로직 적용
const debouncedErrorHandling = debounce((error) => {
  console.log(`error : `);
  console.log(error);

  if (
    error.response?.status === 401 &&
    !window.location.href.includes("login")
  ) {
    // store.dispatch("auth/logout");
    alert(`로그인 인증이 만료되어 로그아웃됩니다`);
    window.localStorage.clear();
    window.location.assign("/login");
  } else if (error.response?.status === 403) {
    alert(`실행할 수 있는 권한이 없습니다.`);
  } else if (error.response?.status === 409) {
    /**
     * 
        COUNSEL_TASK_TIME_CONFLICTS: 방문상담 - 담당자 일정 충돌
        TASTING_TASK_TIME_CONFLICTS: 시식 - 담당자 일정 충돌
        TASTING_VACANCY_TIME_CONFLICTS: 시식 - 공실 충돌
        WEDDING_TASK_TIME_CONFLICTS: 예식 - 담당자 일정 충돌
        WEDDING_VACANCY_TIME_CONFLICTS: 예식 - 공실 충돌
        EVENT_TASK_TIME_CONFLICTS: 행사 - 담당자 일정 충돌
        EVENT_VACANCY_TIME_CONFLICTS: 행사 - 공실 충돌
        TASK_TIME_CONFLICTS: (일정 > 신규등록으로 진행했을 때) 시식/예식/행사 - 담당자 일정 충돌
        VACANCY_TIME_CONFLICTS: (일정 > 신규등록으로 진행했을 때) 시식/예식/행사 - 공실 충돌
     */
    if( error.response.data.message.includes("TASK_SCHEDULE")){
      alert(`해당 일정에는 이미 예식 일정이 존재합니다.`);  
    } else if( error.response.data.message.includes("vacancy") || error.response.data.message.includes("VACANCY")){
      alert(`해당 일정에는 이미 공실 일정이 존재합니다.`);
    } else if (
      error.response.data.message.includes("COUNSEL_TASK_TIME_CONFLICTS")
    ) {
      alert(`해당 일정에는 이미 방문상담 일정이 존재합니다.`);
    } else if (
      error.response.data.message.includes("TASTING_TASK_TIME_CONFLICTS")
    ) {
      alert(`해당 일정에는 이미 시식 일정이 존재합니다.`);
    } else if (
      error.response.data.message.includes("TASTING_VACANCY_TIME_CONFLICTS")
    ) {
      alert(`해당 일정에는 이미 시식 공실 일정이 존재합니다.`);
    } else if (
      error.response.data.message.includes("WEDDING_TASK_TIME_CONFLICTS")
    ) {
      alert(`해당 일정에는 이미 예식 일정이 존재합니다.`);
    } else if (
      error.response.data.message.includes("WEDDING_VACANCY_TIME_CONFLICTS")
    ) {
      alert(`해당 일정에는 이미 예식 공실 일정이 존재합니다.`);
    } else if (
      error.response.data.message.includes("EVENT_TASK_TIME_CONFLICTS")
    ) {
      alert(`해당 일정에는 이미 행사 일정이 존재합니다.`);
    } else if (
      error.response.data.message.includes("EVENT_VACANCY_TIME_CONFLICTS")
    ) {
      alert(`해당 일정에는 이미 행사 공실 일정이 존재합니다.`);
    } else if (
      error.response.data.message.includes("TASK_TIME_CONFLICTS")
    ) {
      alert(`해당 일정에는 이미 일정이 존재합니다.`);
    } else if (
      error.response.data.message.includes("VACANCY_TIME_CONFLICTS")
    ) {
      alert(`해당 일정에는 이미 공실 일정이 존재합니다.`);
    } else {
      alert(`이미 존재하는 데이터에 대한 접근입니다.`);
    }
  } else if (error.response?.status === 500) {
    alert(`잠시만 기다려 주세요.`);
  } else if (error.response?.status >= 501) {
    alert(`서버 연결에 실패하였습니다.`);
  }
}, 1000); // 1초 디바운스 설정

// 레스폰스 헤더
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    debouncedErrorHandling(error);
    return error;
  }
);

export default api;
