import CryptoJS from 'crypto-js'

export function encodeByAES256( data ) {
    let result = '';
  try {
    const secretKey = process.env.VUE_APP_ENC_SECRET_KEY;
    result = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
      iv: CryptoJS.enc.Utf8.parse(''),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });
  } catch (error) {
    console.error(error);
  }
  return result.toString();
}

export function decodeByAES256( data ) {
    if (data === null || data === undefined || !data) {
        return '';
      }
    
      let result = '';
      try {
        const secretKey = process.env.VUE_APP_ENC_SECRET_KEY;
        result = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
          iv: CryptoJS.enc.Utf8.parse(''),
          padding: CryptoJS.pad.Pkcs7,
          mode: CryptoJS.mode.CBC
        });
      } catch (error) {
        console.error(error);
      }
      return result.toString(CryptoJS.enc.Utf8);
}

function makeCryptoKey(key) {
    return key.padEnd(32, " ");
}

/**
 * 특정 문자를 단방향 인코딩
 * ? 암호화 알고리즘 방식 : SHA521
 * ? 인코딩 방식 : base64
 * @param {string} str 인코딩 할 문자
 * @returns {string}
 */
function encodingStringByHash(str) {
    try {
        const hash = CryptoJS.HmacSHA512(str, process.env.VUE_APP_ENC_SECRET_KEY);
        return hash.toString(CryptoJS.enc.Base64);
    } catch (error) {
        console.error(error)
    }
}