import { getManagerId } from '@/utils/id';
import axios from './axios'
import FormData from 'form-data';

const configApi = {
    // default api
    getHealth: () => ({
        method: 'get',
        url: '/health'
    }),

    // auth
    /**
     * 비밀번호 변경
     * @param {*} payload 
     * @returns 
     */
    getChangeAccountsPassword: ( payload ) => ({
        method: 'post',
        url: '/auth/accounts/password',
        payload
    }),

    // clients api
    /**
     * 전체 고객 리스트 조회
     * @returns 
     */
    getClientsList: (payload) => {
        let url = `/clients?page=${payload?.page || 1}&limit=${payload?.limit || 10}${payload?.state?`&state=${payload?.state}`:''}`;
        url += `${ payload?.filter ? `&filter=${payload.filter}`:''}${ payload?.order ? `&order=${payload.order}`:''}${ payload?.weddingHallId ? `&weddingHallId=${payload?.weddingHallId}`:''}`;
        url += `${ payload?.startTime ? `&startTime=${payload?.startTime}`:''}${ payload?.endTime ? `&endTime=${payload.endTime}`:''}${ payload?.name ? `&name=${payload.name}`:''}`;
        return {
            method: 'get',
            url,
        }
    },
    /**
     * 고객 생성
     * @param {*} payload 
     * @returns 
     */
    getMakeClients: (payload) => ({
        method: 'post',
        url: '/clients',
        payload
    }),
    /**
     * 고객 정보 다중삭제
     */
    deleteClients: (payload) => ({
        method: 'delete',
        url: `/clients${payload.query || ''}`,
    }),
    /**
     * ID로 단일 고객 조회
     * @param {*} payload 
     * @returns 
     */
    getCheckClient: (payload) => ({
        method: 'get',
        url: `/clients/${payload.id || 1}?weddingHallId=${payload.weddingHallId || 1}`
    }),
    /**
     * 고객 정보 수정
     * @param {*} payload 
     * @returns 
     */
    getEditClient: (payload) => ({
        method: 'patch',
        url: `/clients/${payload.userid || ''}`,
        payload: payload.body,
    }),
    /**
     * 고객 예식연출 (마이웨딩) 조회
     * @param {*} payload 
     * @returns 
     */
    getClientMyWedding: (payload) => ({
        method: 'get',
        url: `/clients/${payload.userId || ''}/my-wedding`,
    }),
    // managers api
    /**
     * 매니저 리스트 조회 (페이지네이션)
     * @param {*} payload 
     * @returns 
     */
    getManagerInfoList: (payload) => ({
        method: 'get',
        url: `/managers?page=${payload.page || 1}&limit=${payload.limit || 10}&weddingHallId=${payload.weddingHallId || ''}&startTime=${payload.startTime || ''}&endTime=${payload.endTime || ''}&name=${payload.name || ''}`,
    }),
    /**
     * 매니저 생성
     * @returns 
     */
    getRegisterManager: (payload) => ({
        method: 'post',
        url: '/managers',
        payload
    }),
    /**
     * 매니저 다중삭제
     * @param {*} payload 
     * @returns 
     */
    deleteManages: (payload) => ({
        method: 'delete',
        url: `/managers${payload.query || ''}`,
    }),
    /**
     * 매니저 조회
     * @returns 
     */
    getManagerInfo: (payload) => ({
        method: 'get',
        url: `/managers/${payload.id || 1}`
    }),
    /**
     * 매니저 수정
     * @param {*} payload 
     * @returns 
     */
    getEditManagerMemo: (payload) => ({
        method: 'patch',
        url: `/managers/${payload.id || 1}`,
        payload: payload.body || payload,
    }),
    /**
     * 매니저가 작성한 메모 조회
     * @param {*} payload 
     * @returns 
     */
    getShowManagerMemo: (payload) => ({
        method: 'get',
        url: `/managers/${payload.id || 1}/memo?page=${payload.page || 1}&limit=${payload.limit || 10}${ payload?.filter ? `&filter=${payload.filter}`:''}${ payload?.order ? `&order=${payload.order}`:''}${ payload?.startDate ? `&startDate=${payload?.startDate}`:''}${ payload?.endDate ? `&endDate=${payload.endDate}`:''}${ payload?.content ? `&content=${payload.content}`:''}`,
    }),
    /**
     * 매니저가 작성한 메모 생성
     * @param {*} payload 
     * @returns 
     */
    getRegisterManagerMemo: (payload) => ({
        method: 'post',
        url: `/managers/${payload.id || 1}/memo`,
        payload: {
            content: payload.content,
        }
    }),
    /**
     * 매니저가 작성한 메모 다중삭제
     * @param {*} payload 
     * @returns 
     */
    deleteManagersMemos: (payload) => ({
        method: 'delete',
        url: `/managers/${getManagerId() || 1}/memo${payload.query || ''}`,
    }),
    /**
     * 매니저가 작성한 메모 수정
     * @param {*} payload 
     * @returns 
     */
    getEditManagersMemos: (payload) => ({
        method: 'patch',
        url: `/managers/${payload.id || 1}/memo/${payload.memoId || ''}`,
        payload: payload.body,
    }),
    /**
     * 전화 방문상담 일정 생성
     * @param {*} payload 
     * @returns 
     */
    getCreateScheduleCouncel: (payload) => ({
        method: 'post',
        url: `/managers/${payload.client.managerId || 1}/tasks/counsel`,
        payload
    }),
    /**
     * 시식/예식/행사 일정 생성
     * @param {*} payload 
     * @returns 
     */
    getCreateScheduleTasks: (payload) => ({
        method: 'post',
        url: `/managers/${payload.requestId || 1}/tasks`,
        payload: payload.body
    }),
    /**
     * 전체 일정 리스트 조회(페이지네이션)
     * @param {*} payload 
     * @returns 
     */
    getCheckSchedule: (payload) => ({
        method: 'get',
        url: `/managers/${payload.id || 1}/tasks?page=${payload.page || 1}&limit=${payload.limit || 10}&startTime=${payload.startTime || ''}&endTime=${payload.endTime || ''}&state=${payload.state || ''}${payload.filter?`&filter=${payload.filter}`:''}${payload.order?`&order=${payload.order}`:''}`,
    }),
    /**
     * 일별 일정 조회
     * @param {*} payload 
     * @returns 
     */
    getCheckScheduleByDate: (payload) => ({
        method: 'get',
        url: `/managers/${payload.id || 1}/tasks-by-date?startTime=${payload.startTime || ''}&endTime=${payload.endTime || ''}&state=${payload.state || ''}`,
    }),

    /**
     * 홈 화면 일정 조회 with 시식 여부, 연출 여부
     */
    getCheckScheduleTasksWithPush: (payload) => ({
        method: 'get',
        url: `/managers/${payload.id || 1}/tasks-with-push?startTime=${payload.startTime || ''}&endTime=${payload.endTime || ''}`,
    }),

    // wedding-halls api
    /**
     * 웨딩홀 생성
     * @param {*} payload 
     * @returns 
     */
    getCreateWeddingHall: (payload) => ({
        method: 'post',
        url: `/wedding-halls`,
        payload
    }),
    /**
     * 웨딩홀 검색순위 조회
     * @param {*} payload 
     * @returns 
     */
    getWeddingHallSearchRanking: (payload) => ({
        method: 'get',
        url: `/wedding-halls/search-ranking?managerId=${payload.manargerId || 1}&limit=${payload.limit || 10}`,
    }),
    /**
     * 웨딩홀 단일 조회
     * @param {*} payload 
     * @returns 
     */
    getWeddingHallInfo: (payload) => ({
        method: 'get',
        url: `/wedding-halls/${payload.id || ''}`,
    }),
    /**
     * 웨딩홀 수정
     * @param {*} payload 
     * @returns 
     */
    getModifyWeddingHall: (payload) => ({
        method: 'patch',
        url: `/wedding-halls/${payload.id || ''}`,
        payload : payload.body,
    }),
    // wedding-talk api
    /**
     * 공통 채팅설정 조회
     * @param {*} payload 
     * @returns 
     */
    getSelectWeddingTalkSettings: (payload) => ({
        method: 'get',
        url: `/wedding-talk/settings?weddingHallId=${payload.weddingHallId || ''}`,
    }),
    /**
     * 공통 채팅설정 수정
     * @param {*} payload 
     * @returns 
     */
    getEditWeddingTalkSettings: (payload) => ({
        method: 'patch',
        url: `/wedding-talk/settings`,
        payload
    }),
    /**
     * 자주 쓰는 답변 id로 조회
     * @param {*} payload 
     * @returns 
     */
    getSelectWeddingTalkCommonRepliesById: (payload) => ({
        method: 'get',
        url: `/wedding-talk/common-replies/${payload.id || ''}`,
    }),
    /**
     * 자주 쓰는 답변 수정
     * @param {*} payload 
     * @returns 
     */
    getEditWeddingTalkCommonRepliesById: (payload) => ({
        method: 'patch',
        url: `/wedding-talk/common-replies/${payload.id || ''}`,
        payload: payload.body || payload,
    }),
    /**
     * 자주 쓰는 답변 리스트 조회
     */
    getSelectWeddingTalkCommonReplies: (payload) => ({
        method: 'get',
        url: `/wedding-talk/common-replies?page=${payload.page || 1}&limit=${payload.limit || 10}&weddingHallId=${payload.weddingHallId || 1}&query=${payload.query || ''}`,
    }),
    /**
     * 자주 쓰는 답변 생성
     * @param {*} payload 
     * @returns 
     */
    getRegisterWeddingTalkCommonReplies: (payload) => ({
        method: 'post',
        url: `/wedding-talk/common-replies`,
        payload
    }),
    /**
     * 자주 쓰는 답변 다중삭제
     */
    getDeleteWeddingTalkCommonReplies: (payload) => ({
        method: 'delete',
        url: `/wedding-talk/common-replies${payload.query || ''}`,
    }),
    /**
     * 채팅방 목록조회
     * @param {*} payload 
     * @returns 
     */
    getSelectWeddingTalkChatrooms: (payload) => ({
        method: 'get',
        url: `/wedding-talk/chatrooms?weddingHallId=${payload?.weddingHallId || ''}&chatroomState=${payload?.chatroomState || ''}`,
    }),
    /**
     * 채팅방 상세조회
     * @param {*} payload 
     * @returns 
     */
    getSelectWeddingTalkChatroomsDetail: (payload) => ({
        method: 'get',
        url: `/wedding-talk/chatrooms/${payload.id || ''}?weddingHallId=${payload?.weddingHallId || ''}`,
    }),
    /**
     * 채팅방 수정
     * @param {*} payload 
     * @returns 
     */
    getEditWeddingTalkChatrooms: (payload) => ({
        method: 'patch',
        url: `/wedding-talk/chatrooms/${payload.id || ''}?weddingHallId=${payload?.weddingHallId || ''}`,
        payload: payload.body,
    }),
    /**
     * 대기 목록 채팅방 담당하기
     * @param {*} payload 
     * @returns 
     */
    putManageChatrooms: (payload) => ({
        method: 'put',
        url: `/wedding-talk/chatrooms/${payload.id || ''}?weddingHallId=${payload?.weddingHallId || ''}&managerId=${payload?.managerId || ''}`,
    }),
    /**
     * 채팅 종료하기
     * @param {*} payload 
     * @returns 
     */
    deleteExitChatRoom: (payload) => ({
        method: 'delete',
        url: `/wedding-talk/chatrooms/${payload.id || ''}?weddingHallId=${payload?.weddingHallId || ''}&managerId=${payload?.managerId || ''}`,
    }),

    /**
     * 채팅방 메시지 조회
     * @param {*} payload 
     * @returns 
     */
    getSelectWeddingTalkChatroomMsg: (payload) => ({
        method: 'get',
        url: `/wedding-talk/chatrooms/${payload.chatroomId || 1}/messages?weddingHallId=${payload?.weddingHallId || ''}&page=${payload.page || 1}&limit=${payload.limit || 30}`,
    }),
    /**
     * 메시지 전송
     * @param {*} payload 
     * @returns 
     */
    requestSendWeddingTalkChatroomMsg: (payload) => ({
        method: 'post',
        url: `/wedding-talk/chatrooms/${payload.chatroomId || 1}/messages?weddingHallId=${payload?.weddingHallId || ''}`,
        payload
    }),
    /**
     * 채팅방 신규 메시지 조회
     * @param {*} payload 
     * @returns 
     */
    getSelectNewWeddingTalkChatroomMsg: (payload) => ({
        method: 'get',
        url: `/wedding-talk/chatrooms/${payload.chatroomId || 1}/messages?weddingHallId=${payload?.weddingHallId || ''}&messageId=${payload.messageId || 1}`,
    }),
    /**
     * 채팅방 방문상담 예약
     * @param {*} payload 
     * @returns 
     */
    putReservationVisitCounsel: (payload) => ({
        method: 'put',
        url: `/wedding-talk/chatrooms/${payload.chatroomId || 1}/counsel?weddingHallId=${payload?.weddingHallId || ''}&managerId=${payload.managerId || 1}`,
        payload: payload.body,
    }),

    // landing api
    /**
     * 랜딩페이지 제휴 문의 조회
     * @param {*} payload 
     * @returns 
     */
    getLandingInquiries: (payload) => ({
        method: 'get',
        url: `/landing/inquiries?page=1&limit=10`,
    }),
    /**
     * 랜딩페이지 제휴 문의 조회
     * @param {*} payload 
     * @returns 
     */
    getLandingInquiriesList: (payload) => ({
        method: 'get',
        url: `/landing/inquiries?page=${payload.page || 1}&limit=${payload.limit || 30}`,
    }),
    // wedding-venue api

    /**
     * 예식장 생성
     * @param {*} payload 
     * @returns 
     */
    getCreateWeddingHallVenue: (payload) => ({
        method: 'post',
        url: `/wedding-halls/${payload.id || 1}/venues`,
        payload: payload.formData
    }),
    /**
     * 예식장 리스트 조회
     * @param {*} payload 
     * @returns 
     */
    getWeddingHallVenueList: (payload) => ({
        method: 'get',
        url: `/wedding-halls/${payload.id || 1}/venues?page=${payload.page || 1}&limit=${payload.limit || 20}${ payload.content ? `&venueName=${payload.content}`:''}`,
    }),
    /**
     * 예식장 다중삭제
     * @param {*} payload 
     * @returns 
     */
    deleteWeddingHallVenue: (payload) => ({
        method: 'delete',
        url: `/wedding-halls/${payload.id || 1}/venues${payload.query || ''}`,
    }),
    /**
     * 예식장 단일 조회
     * @param {*} payload 
     * @returns 
     */
    getWeddingHallVenueInfo: (payload) => ({
        method: 'get',
        url: `/wedding-halls/${payload.id || 1}/venues/${payload.venueId || ''}`,
    }),
    /**
     * 예식장 수정
     * @param {*} payload 
     * @returns 
     */
    getEditWeddingHallVenue: (payload) => ({
        method: 'patch',
        url: `/wedding-halls/${payload.weddingHallId || 1}/venues/${payload.venueId || ''}`,
        payload : payload.formData,
    }),
    /**
     * 일별 일정 조회
     * @param {*} payload 
     * @returns 
     */
    getSelectWeddingHallVenueInfoByDate: (payload) => ({
        method: 'get',
        url: `/wedding-halls/${payload.weddingHallId || 1}/venues-by-date?startTime=${payload.startTime || ''}&endTime=${payload.endTime || ''}&state=${ payload.state ? encodeURI(payload.state) : encodeURI('전체')}`,
    }),
    // vacancy api
    /**
     * 노출 설정
     * @param {*} payload 
     * @returns 
     */
    getPatchVacancyAvailability: (payload) => ({
        method: 'patch',
        url: `/vacancy/availability?venueId=${payload.venueId}&startTime=${payload.startTime}&endTime=${payload.endTime}&availability=${payload.availability}`,
    }),
    /**
     * 금액 설정
     * @param {*} payload 
     * @returns 
     */
    getPatchVacancyRebate: (payload) => ({
        method: 'patch',
        url: `/vacancy/rebate?venueId=${payload.venueId}&startTime=${payload.startTime}&endTime=${payload.endTime}&rebate=${payload.rebate}`,
    }),
    /**
     * 공실 정보 전체 조회
     * @param {*} payload 
     * @returns 
     */
    getSelectVacancyAllList: (payload) => ({
        method: 'get',
        url: `/vacancy?weddingHallId=${payload.weddingHallId || 1}&startTime=${payload.startTime || ''}&endTime=${payload.endTime || ''}`,
    }),
    /**
     * 홀 - 공실률 (역대 공실률)
     * @param {*} payload 
     * @returns 
     */
    getSelectVacancyHistoricalRates: (payload) => ({
        method: 'get',
        url: `/vacancy/historical-rates?weddingHallId=${payload.weddingHallId || 1}&startTime=${payload.startTime || ''}&endTime=${payload.endTime || ''}`,
    }),

    // business-relations api
    /**
     * 플래너 업체 생성
     * @param {*} payload 
     * @returns 
     */
    getRegisterBusinessPlanners: (payload) => ({
        method: 'post',
        url: '/business-relations/planners',
        payload
    }),
    /**
     * 플래너 업체 리스트 조회 (페이지네이션)
     * @param {*} payload 
     * @returns 
     */
    getSelectBusinessPlanners: (payload) => {
        let url = `/business-relations/planners?page=${payload.page || 1}&limit=${payload.limit  || 10}&weddingHallId=${payload.weddingHallId || 1}`; 
        url += `${ payload.companyName ? `&companyName=${payload.companyName}`:''}`;
        return {
            method: 'get',
            url,
        }
    },
    /**
     * 플래너 업체 다중삭제
     * @param {*} payload 
     * @returns 
     */
    deleteBusinessPlanners: (payload) => ({
        method: 'delete',
        url: `/business-relations/planners${payload.query || ''}`,
    }),

    /**
     * 일반 업체 생성
     * @param {*} payload 
     * @returns 
     */
    getRegisterBusinessVendors: (payload) => ({
        method: 'post',
        url: '/business-relations/vendors',
        payload
    }),
    /**
     * 일반 업체 리스트 조회 (페이지네이션)
     * @param {*} payload 
     * @returns 
     */
    getSelectBusinessVendors: (payload) => {
        let url = `/business-relations/vendors?page=${payload.page || 1}&limit=${payload.limit || 10}&weddingHallId=${payload.weddingHallId || 1}`;
        url += `${ payload.name ? `&name=${payload.name}`:''}`;
        return {
            method: 'get',
            url,
        }
    },
    /**
     * 일반 업체 다중삭제
     * @param {*} payload 
     * @returns 
     */
    deleteBusinessVendors: (payload) => ({
        method: 'delete',
        url: `/business-relations/vendors${payload.query || ''}`,
    }),
    /**
     * 일반 업체 단일 조회
     * @param {*} payload 
     * @returns 
     */
    getSelectBusinessVendorsById: (payload) => ({
        method: 'get',
        url: `/business-relations/vendors/${payload.id || 1}`,
    }),
    /**
     * 일반 업체 수정
     * @param {*} payload 
     * @returns 
     */
    getEditBusinessVendorsById: (payload) => ({
        method: 'patch',
        url: `/business-relations/vendors/${payload.id || 1}`,
        payload : payload.body,
    }),
    /**
     * 일반 업체 거래처 상품 생성
     * @param {*} payload 
     * @returns 
     */
    getGenerateItemsBusinessVendors: (payload) => ({
        method: 'post',
        url: `/business-relations/vendors/${payload.id || 1}/items`,
        payload : payload.body,
    }),
    /**
     * 일반 업체 거래처 상품 조회(페이지네이션)
     * @param {*} payload 
     * @returns 
     */
    getSelectBusinessVendorsItems: (payload) => ({
        method: 'get',
        url: `/business-relations/vendors/${payload.id || 1}/items?page=${payload.page || 1}&limit=${payload.limit  || 10}`,
    }),
    /**
     * 일반 업체 거래처 상품 다중삭제
     * @param {*} payload 
     * @returns 
     */
    deleteBusinessVendors: (payload) => ({
        method: 'delete',
        url: `/business-relations/vendors${payload.query || ''}`,
    }),
    /**
     * 거래처 정산 (단일) - 총 건수, 총 금액, 총 정산금액, 총 미정산 금액
     * @param {*} payload 
     * @returns 
     */
    getSettlementOneOfBusinessVendorsStats: (payload) => ({
        method: 'get',
        url: `/business-relations/vendors/${payload.id || 1}/stats`,
    }),
    /**
     * 웨딩홀에 연관된 거래처 아이템 모두 조회
     * @param {*} payload 
     * @returns 
     */
    getAllRelatedVendorItems: (payload) => ({
        method: 'get',
        url: `/business-relations/vendor-items?weddingHallId=${payload.weddingHallId || 1}${payload.page?`&page=${payload.page}`:''}${payload.limit?`&limit=${payload.limit}`:''}`,
    }),
    
    // settlements api
    /**
     * 정산 상세페이지 - 예식 내용
     * @param {*} payload 
     * @returns 
     */
    getSettlementsWeddingDetailPage: (payload) => ({
        method: 'get',
        url: `/settlements/${payload.id}/wedding-detail`,
    }),
    /**
     * 정산 상세페이지 - 고객 정산
     * @param {*} payload 
     * @returns 
     */
    getSettlementsClientDetail: (payload) => ({
        method: 'get',
        url: `/settlements/${payload.id}/client`,
    }),
    /**
     * 정산 상세페이지 - 고객 정산 "정산하기"
     * @param {*} payload 
     * @returns 
     */
    getSettlementsClientSettleUp: (payload) => ({
        method: 'patch',
        url: `/settlements/${payload.id}/client`,
        payload: payload.body,
    }),
    /**
     * 정산 상세페이지 - 플래너 정산
     * @param {*} payload 
     * @returns 
     */
    getSettlementsPlannerDetail: (payload) => ({
        method: 'get',
        url: `/settlements/${payload.id}/planner`,
    }),
    /**
     * 정산 상세페이지 - 플래너 정산 "정산하기"
     */
    getSettlementsPlannerSettleUp: (payload) => ({
        method: 'patch',
        url: `/settlements/${payload.id}/planner`,
        payload: payload.body,
    }),
    /**
     * 정산 상세페이지 - 거래처 상품
     * @param {*} payload 
     * @returns 
     */
    getSettlementsVendorDetail: (payload) => ({
        method: 'get',
        url: `/settlements/${payload.id}/vendor?page=${payload.page || 1}&limit=${payload.limit || 10}`,
    }),
    /**
     * 정산 상세페이지 - 거래처 상품 "정산하기"
     * @param {*} payload 
     * @returns 
     */
    getSettlementsVendorSettleUp: (payload) => ({
        method: 'patch',
        url: `/settlements/${payload.id}/vendor?vendorItemId=${payload.body.vendorItemId}&amount=${payload.body.amount}`,
    }),
    /**
     * 정산 상세페이지 - 거래처 상품 (총 금액, 정산 금액, 미정산 금액)
     * @param {*} payload 
     * @returns 
     */
    getSettlementsVendorStatsDetail: (payload) => ({
        method: 'get',
        url: `/settlements/${payload.id}/vendor/stats`,
    }),
    /**
     * 정산 상세페이지 - "모두 정산하기"
     * @param {*} payload 
     * @returns 
     */
    getSettlementsVendorStatsSettleUp: (payload) => ({
        method: 'patch',
        url: `/settlements/${payload.id}`,
        payload
    }),
    /**
     * 정산서 조회하기
     * @param {*} payload 
     * @returns 
     */
    getSelectSettlement: (payload) => ({
        method: 'get',
        url: `/settlements/${payload.id}`,
    }),

    /**
     * 고객 정산 - 리스트 요청
     * @param {*} payload 
     * @returns 
     */
    getSettlementsClientssList: (payload) => {
        let url = `/settlements/clients?weddingHallId=${payload.weddingHallId}`;
        url += `${payload.page?`&page=${payload.page}`:''}${payload.limit?`&limit=${payload.limit||20}`:''}`;
        url += `${payload.filter?`&filter=${payload.filter}`:''}${payload.order?`&order=${payload.order}`:''}`;
        url += `${payload.clientName?`&clientName=${payload.clientName}`:''}${payload.startDate?`&startDate=${payload.startDate}`:''}${payload.endDate?`&endDate=${payload.endDate}`:''}`;
        url += `${payload.managerId?`&managerId=${payload.managerId}`:''}`;
        return     {
            method: 'get',
            url
        }
    },
    /**
     * 고객 정산 - 총 계약금액, 총 금액, 총 정산금액, 총 미정산 금액
     * @param {*} payload 
     * @returns 
     */
    getSettlementsClientsStatsList: (payload) => ({
        method: 'get',
        url: `/settlements/clients/stats?weddingHallId=${payload.weddingHallId || 1}${payload.clientName?`&clientName=${payload.clientName}`:''}${payload.filter?`&filter=${payload.filter}`:''}${payload.order?`&order=${payload.order}`:''}${payload.startDate?`&startDate=${payload.startDate}`:''}${payload.endDate?`&endDate=${payload.endDate}`:''}${payload.managerId?`&managerId=${payload.managerId}`:''}`,
    }),
    /**
     * 리베이트 정산 - 리스트 요청
     * @param {*} payload 
     * @returns 
     */
    getSettlementsRebatesList: (payload) => {
        let url = `/settlements/rebates?weddingHallId=${payload.weddingHallId || 1}${payload.page?`&page=${payload.page}`:''}${payload.limit?`&limit=${payload.limit}`:''}`;
        url += `${payload.clientName?`&clientName=${payload.clientName}`:''}${payload.filter?`&filter=${payload.filter}`:''}${payload.order?`&order=${payload.order}`:''}`;
        url += `${payload.startDate?`&startDate=${payload.startDate}`:''}${payload.endDate?`&endDate=${payload.endDate}`:''}${payload.managerId?`&managerId=${payload.managerId}`:''}`;
        return {
            method: 'get',
            url,
        }
    },
    /**
     * 리베이트 정산 - 총 계약금액, 총 금액, 총 정산금액, 총 미정산 금액
     * @param {*} payload 
     * @returns 
     */
    getSettlementsRebatesStatsList: (payload) => {
        let url = `/settlements/rebates/stats?weddingHallId=${payload.weddingHallId || 1}${payload.clientName?`&clientName=${payload.clientName}`:''}${payload.startDate?`&startDate=${payload.startDate}`:''}${payload.endDate?`&endDate=${payload.endDate}`:''}${payload.managerId?`&managerId=${payload.managerId}`:''}`;
        return {
            method: 'get',
            url,
        }
    },
    /**
     * 거래처 정산 - 리스트
     * @param {*} payload 
     * @returns 
     */
    getSettlementsVendorsList: (payload) => {
        let url = `/settlements/vendors?weddingHallId=${payload.weddingHallId || 1}&page=${payload.page || 1}&limit=${payload.limit || 10}`;
        url += `${payload.clientName?`&clientName=${payload.clientName}`:''}${payload.startDate?`&startDate=${payload.startDate}`:''}${payload.endDate?`&endDate=${payload.endDate}`:''}`;
        url += `${payload.filter?`&filter=${payload.filter}`:''}${payload.order?`&order=${payload.order}`:''}${payload.managerId?`&managerId=${payload.managerId}`:''}`;

        return {
            method: 'get',
            url,
        }
    },
    /**
     * 거래처 정산 단일 삭제
     * @param {*} payload 
     * @returns 
     */
    deleteSettlementsVendor: (payload) => ({
        method: 'delete',
        url: `/settlements/vendors${payload || ''}`,
    }),
    /**
     * 거래처 정산 - 총 건수, 총 금액, 총 정산금액, 총 미정산 금액
     * @param {*} payload 
     * @returns 
     */
    getSettlementVendorsStatsList: (payload) => ({
        method: 'get',
        url: `/settlements/vendors/stats?weddingHallId=${payload.weddingHallId || 1}${payload.clientName?`&clientName=${payload.clientName}`:''}${payload.startDate?`&startDate=${payload.startDate}`:''}${payload.endDate?`&endDate=${payload.endDate}`:''}${payload.managerId?`&managerId=${payload.managerId}`:''}`,
    }),
    /**
     * 정산 다중삭제
     * @param {*} payload 
     * @returns 
     */
    deleteSettlementMultiple: (payload) => ({
        method: 'delete',
        url: `/settlements${payload.query || ''}`,
        payload
    }),
    /**
     * 정산 생성하기 (예식 완료하기)
     * @param {*} payload 
     * @returns 
     */
    getExcuteSettlements: (payload) => ({
        method: 'post',
        url: `/settlements?clientId=${payload.clientId}`,
        payload: payload.body
    }),
    // estimates api
    /**
     * 견적 조회하기
     * @param {*} payload 
     * @returns 
     */
    getSelectEstimates: (payload) => ({
        method: 'get',
        url: `/estimates?clientId=${payload.clientId}`,
    }),
    /**
     * 견적 생성하기
     * @param {*} payload 
     * @returns 
     */
    getMakeEstimates: (payload) => ({
        method: 'post',
        url: `/estimates?clientId=${payload.clientId}`,
        payload: payload.body
    }),
    /**
     * 견적 고객 웨딩톡 채팅방에 전송하기 (견적서 > 전송하기)
     * @param {*} payload 
     * @returns 
     */
    postSubmitEstimates2App: (payload) => ({
        method: 'post',
        url: `/estimates/messages?clientId=${payload.clientId||''}&weddingHallId=${payload.weddingHallId||''}&estimateId=${payload.estimateId||''}`,
    }),
    // contracts api
    /**
     * 계약 조회하기
     * @param {*} payload 
     * @returns 
     */
    getSelectContracts: (payload) => ({
        method: 'get',
        url: `/contracts?clientId=${payload?.clientId|| ''}&weddingHallId=${payload.weddingHallId || ''}`,
    }),
    /**
     * 계약 생성하기
     * @param {*} payload 
     * @returns 
     */
    getMakeContracts: (payload) => ({
        method: 'post',
        url: `/contracts?clientId=${payload?.clientId|| ''}&weddingHallId=${payload.weddingHallId || ''}`,
        payload: payload.body
    }),
    // statistics api
    /**
     * 일반 통계 - 상담 요약
     * @param {*} payload 
     * @returns 
     */
    getStatisticsCounselOverall: (payload) => ({
        method: 'get',
        url: `/statistics/counsel-overall?weddingHallId=${payload.id || ''}${payload.startDate?`&startDate=${payload.startDate}`:''}${payload.endDate?`&endDate=${payload.endDate}`:''}`,
    }),
    /**
     * 일반 통계 - 상담 유형 & 계약 비율
     * @param {*} payload 
     * @returns 
     */
    getStatisticsCounselContractRatio: (payload) => ({
        method: 'get',
        url: `/statistics/counsel-and-contract-ratio?weddingHallId=${payload.id || ''}${payload.startDate?`&startDate=${payload.startDate}`:''}${payload.endDate?`&endDate=${payload.endDate}`:''}`,
    }),

    /**
     * 일반 통계 - 공실
     * @param {*} payload 
     * @returns 
     */
    getStatisticsCounselVacancyTrend: (payload) => ({
        method: 'get',
        url: `/statistics/vacancy-trend?weddingHallId=${payload.id || ''}${payload.startDate?`&startDate=${payload.startDate}`:''}${payload.endDate?`&endDate=${payload.endDate}`:''}`,
    }),

    /**
     * 마케팅 통계 - 매출
     * @param {*} payload 
     * @returns 
     */
    getStatisticsMarketingSales: (payload) => ({
        method: 'get',
        url: `/statistics/marketing-sales?weddingHallId=${payload.id || ''}${payload.startDate?`&startDate=${payload.startDate}`:''}${payload.endDate?`&endDate=${payload.endDate}`:''}`,
    }),

    /**
     * 마케팅 통계 - 인원
     * @param {*} payload 
     * @returns 
     */
    getStatisticsMarketingGuests: (payload) => ({
        method: 'get',
        url: `/statistics/marketing-guests?weddingHallId=${payload.id || ''}${payload.startDate?`&startDate=${payload.startDate}`:''}${payload.endDate?`&endDate=${payload.endDate}`:''}`,
    }),

    /**
     * 마케팅 통계 - 업체별 리베이트 list
     * @param {*} payload 
     * @returns 
     */
    getStatisticsMarketingRebateList: (payload) => ({
        method: 'get',
        url: `/statistics/marketing-rebate-list?weddingHallId=${payload.id || ''}${payload.startDate?`&startDate=${payload.startDate}`:''}${payload.endDate?`&endDate=${payload.endDate}`:''}`,
    }),

    /**
     * 마케팅 통계 - 업체별 리베이트 금액
     * @param {*} payload 
     * @returns 
     */
    getStatisticsMarketingRebateAmount: (payload) => ({
        method: 'get',
        url: `/statistics/marketing-rebate-amount?weddingHallId=${payload.id || ''}${payload.startDate?`&startDate=${payload.startDate}`:''}${payload.endDate?`&endDate=${payload.endDate}`:''}`,
    }),

    // printouts api
    /**
     * 일정(daily task) 프린트하기
     * @param {*} payload 
     * @returns 
     */
    getPrintOutsDailyTask: (payload) => ({
        method: 'get',
        url: `/printouts/daily-task?managerId=${payload.managerId || ''}&startTime=${payload.startTime || ''}&endTime=${payload.endTime || ''}`,
    }),
    /**
     * 일정(전체 일정-월간) 프린트하기
     * @param {*} payload 
     * @returns 
     */
    getPrintOutsMonthlyTask: (payload) => ({
        method: 'get',
        url: `/printouts/monthly-task?managerId=${payload.managerId || 1}&startTime=${payload.startTime || ''}&endTime=${payload.endTime || ''}`,
    }),
    /**
     * 공실 관리 - 프린트 하기
     * @param {*} payload 
     * @returns 
     */
    getPrintOutsMonthlyVacancy: (payload) => ({
        method: 'get',
        url: `/printouts/monthly-vacancy?weddingHallId=${payload.weddingHallId || 1}&startTime=${payload.startTime || ''}&endTime=${payload.endTime || ''}`,
    }),
    /**
     * 고객 관리 - 액셀 다운로드
     * @param {*} payload 
     * @returns 
     */
    getPrintOutsClients: (payload) => ({
        method: 'get',
        url: `/printouts/clients?weddingHallId=${payload.weddingHallId || 1}`,
    }),
    //================================== 마스터 어드민용 ================================================= //
    /**
     * 대기 리스트 가져오기
     * @param {*} payload 
     * @returns 
     */
    getCompanyList: ( payload ) => {
        let url = `/company/list?masterId=${payload.id || 1}&page=${payload.page||''}&limit=${payload.limit||''}`;
        url+=`&startDate=${payload.startDate||''}&endDate=${payload.endDate||''}&name=${payload.name||''}`;
        return {
            method: 'get',
            url,
        }
    },
    /**
     * 상세 페이지 조회, 수정을 위한 단일 페이지 정보 가져오기
     * @param {*} payload 
     * @returns 
     */
    getDetailCompany: ( payload ) => ({
        method: 'get',
        url: `/company/detail?companyId=${payload.id || 1}`,
    }),
    /**
     * 아이디 강제 생성하기
     */
    getGenerateCompanyAccount: ( payload ) => ({
        method: 'post',
        url: `/company`,
        payload
    }),
    /**
     * 승인하기
     */
    getConfirmLandingCompany: ( payload ) => ({
        method: 'post',
        url: `/company?companyId=${payload.id || 1}`,
        payload: payload.body
    }),
    /**
     * 거부하기
     */
    getRejectLandingCompany: ( payload ) => ({
        method: 'delete',
        url: `/company?companyId=${payload.id || 1}`,
    }),
    // notifications api
    // 고객에게 연출 작성 요구 푸시 전송
    postNotificationPushUserWedding: ( payload ) => ({
        method: 'post',
        url: `/notification/push/my-wedding?managerId=${payload?.managerId || ''}&clientId=${payload?.clientId || ''}`,
    }),
    // 고객에게 시식 여부 확인 푸시 전송
    postNotificationPushTasting: ( payload ) => ({
        method: 'post',
        url: `/notification/push/tasting?managerId=${payload?.managerId || ''}&clientId=${payload?.clientId || ''}`,
    }),

    // location api
    // 지역 (시/도) 목록 조회
    getLocationsCitiesList: ( payload ) => ({
        method: 'get',
        url: `/locations/cities`,
    }),
    // 지역 (시/군/구) 목록 조회
    getLocationsCitiesDistrictsList: ( payload ) => ({
        method: 'get',
        url: `/locations/cities/${payload?.cityName}/districts`,
    }),
    // 지하철 호선 목록 조회
    getLocationsSubwayLines: () => ({
        method: 'get',
        url: `/locations/subway-lines`,
    }),
}

function isMultipartApi( name ){
    if( name === `getCreateWeddingHallVenue` ){
        return true;
    } else if ( name === `getEditWeddingHallVenue` ){
        return true;
    } else if ( name === `getModifyWeddingHall` ){
        return true;
    } else {
        return false;
    }
}

async function api(name, payload) {
    const config = configApi[name](payload)
    try {
        let res = null

        const contentType = isMultipartApi(name) ? 'multipart/form-data' : 'application/json; charset=utf-8';

        const headers = {
            'Content-Type': contentType,
        };
        
        if (config?.payload == undefined) {
            res = await axios[config.method](config.url, headers)
        }
        else {
            res = await axios[config.method](
                config.url,
                config.method != 'delete' ? config?.payload : { data: config?.payload },
                { headers }
            );
        }
        return res
    } catch (error) {
        return error;
    }
}

export async function getCsvFile( name, payload ){
    const config = configApi[name](payload)
    try {
        let res = null

        const headers = {
            // "content-disposition": 'attachment',
            // "Content-Type": "text/csv; charset=utf-8",
            // "Content-Type": "application/octet-stream",
        };
        
        if (config?.payload == undefined) {
            res = await axios[config.method](config.url, headers)
        }
        else {
            res = await axios[config.method](
                config.url,
                config.method != 'delete' ? config?.payload : { data: config?.payload },
                { headers }
            );
        }
        console.log(`getCsvFile : ${JSON.stringify(res)}`);
        return res
    } catch (error) {
        return error;
    }
}

export default api