import { createStore } from "vuex";

import auth from "./modules/auth";
import chat from "./modules/chat";
import vacancy from "./modules/vacancy";

const store = createStore({
  modules: {
    auth,
    chat,
    vacancy,
  },
});

export default store;
