// 웨딩홀에서 사용하는 id를 반환
export function getManagerId(){
    const managerId = window.localStorage.getItem('managerId') || null;
    if( Number( managerId )){
        return Number( managerId);
    } else {
        return 0;
    }
}

// 웨딩홀 id를 반환
export function getWeddingHallId(){
    const weddingHallId = window.localStorage.getItem('weddingHallId') || null;
    if( Number( weddingHallId )){
        return Number( weddingHallId);
    } else {
        return 0;
    }
}

export function getVenueId(){
    return 1;
}

export function getPlannerId(){
    return 1;
}