import api from "@/boot/axios";

export const login = async (payload) => {
  return await api.post("auth/tokens", payload);
};

export const logout = async (payload) => {
  return await api.delete("auth/tokens", payload);
};

export const refreshToken = async () => {
  const refresh_token = localStorage.getItem("refresh_token");
  return api.get("auth/refresh", {
    headers: { Authorization: `Bearer ${refresh_token}` },
  });
};
